<template>
  <div v-if="!isLoading">
    <StatPanel
      :column="statColumn"
      :data="data.analysis"
      :isLoading="isLoading"
    />

    <div class="my-3">
      <b-input-group>
        <b-form-input
          class="search-bar"
          @keyup.enter="fetchData"
          placeholder="Search Name, Member ID, Tel., Email"
          v-model="filter.search"
        >
        </b-form-input>
        <b-input-group-append is-text class="mr-2">
          <b-iconstack font-scale="2" type="submit" @click="fetchData">
            <b-icon stacked icon="search" scale="0.5" variant="grey"></b-icon>
          </b-iconstack>
        </b-input-group-append>
        <b-button @click="showFilter()" class="btn button btn-mobile px-4">
          <font-awesome-icon
            icon="filter"
            title="filter-btn"
            class="main-color mr-0 mr-sm-1"
          />
          <span class="d-none d-sm-inline">Filter</span>
        </b-button>
        <b-dropdown
          text="Options"
          class="ml-2 main-color btn-filter"
          :disabled="listEditOrder.length == 0"
        >
          <b-dropdown-item
            @click="confirmAction(1)"
            :disabled="
              isSelect.filter(
                (el) =>
                  el.random_reward_order_status == 4 ||
                  el.random_reward_order_status == 3 ||
                  el.random_reward_order_status == 2 ||
                  el.random_reward_order_status == 1 ||
                  el.random_reward_order_status == 0 ||
                  (el.random_reward_order_status == 5 &&
                    (el.pick_up_type == 1 || el.pick_up_type == 2))
              ).length > 0
            "
          >
            <span>รอจัดส่ง</span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="confirmAction(2)"
            :disabled="
              isSelect.filter(
                (el) =>
                  el.random_reward_order_status == 4 ||
                  el.random_reward_order_status == 3 ||
                  el.random_reward_order_status == 1 ||
                  el.random_reward_order_status == 2 ||
                  el.random_reward_order_status == 0 ||
                  (el.random_reward_order_status == 5 && el.pick_up_type == 1)
              ).length > 0
            "
          >
            <span>รอกรอกข้อมูล</span>
          </b-dropdown-item>
          <b-dropdown-item
            @click="confirmAction(3)"
            :disabled="
              isSelect.filter(
                (el) =>
                  el.random_reward_order_status == 4 ||
                  el.random_reward_order_status == 2 ||
                  el.random_reward_order_status == 3 ||
                  el.random_reward_order_status == 1 ||
                  el.random_reward_order_status == 0 ||
                  (el.random_reward_order_status == 5 && el.pick_up_type == 2)
              ).length > 0
            "
          >
            <span>รับที่ร้านค้า</span>
          </b-dropdown-item>

          <b-dropdown-item @click="confirmAction(5)" :disabled="true">
            <span>กำลังจัดเตรียมสินค้า</span>
          </b-dropdown-item>

          <b-dropdown-item
            @click="confirmAction(4)"
            :disabled="
              isSelect.filter(
                (el) =>
                  el.random_reward_order_status == 2 ||
                  el.random_reward_order_status == 5 ||
                  el.random_reward_order_status == 4 ||
                  el.random_reward_order_status == 0
              ).length > 0
            "
          >
            <span>สำเร็จ</span>
          </b-dropdown-item>
        </b-dropdown>
        <button
          type="button"
          class="btn button btn-mobile px-4 ml-2"
          @click="exportData"
          :disabled="selectAllList == 0 && listEditOrder == 0"
        >
          <font-awesome-icon icon="plus" class="text-black d-sm-none" />
          <span class="d-none d-sm-block">
            <font-awesome-icon icon="file-upload" class="pointer" />
            Export
          </span>
        </button>
      </b-input-group>
    </div>

    <b-table
      :fields="fields"
      :items="data.order_detail.redeem_orders"
      :busy="isBusy"
      show-empty
      empty-text="No matching records found"
      responsive
    >
      <template v-slot:head(id)="">
        <b-form-group class="mb-0">
          <b-form-checkbox
            v-model="selectAllList"
            @change="selectAllTable"
            :unchecked-value="0"
            :value="1"
          ></b-form-checkbox>
        </b-form-group>
      </template>
      <template v-slot:cell(id)="data">
        <b-form-group class="mb-0">
          <!-- v-if="
            data.item.random_reward_item_type == 2 ||
            data.item.random_reward_item_type == 3
          " -->
          <b-form-checkbox
            v-model="listEditOrder"
            :value="data.item.id"
            @change="handleSelect(data.item.id, data.item)"
          ></b-form-checkbox>
        </b-form-group>
      </template>
      <template v-slot:cell(name)="data">
        <div>
          {{ data.item.name }}
          <span v-if="data.item.random_reward_item_type == 4">
            ({{ data.item.point }})</span
          >
        </div>
      </template>
      <template v-slot:cell(updated_time)="data">
        <div>{{ data.item.updated_time | moment($formatDateNew) }}</div>
        <div class="time-color">
          {{ data.item.updated_time | moment("HH:mm:ss") }}
        </div>
      </template>
      <template v-slot:cell(pick_up_type)="data">
        <div>{{ textPickup(data.item.pick_up_type) }}</div>
      </template>
      <template v-slot:cell(random_reward_order_status)="data">
        <div :class="textStatus(data.item.random_reward_order_status, 2)">
          {{ textStatus(data.item.random_reward_order_status, 1) }}
        </div>
      </template>
      <template v-slot:table-busy>
        <div class="text-center text-black my-2">
          <b-spinner class="align-middle"></b-spinner>
          <strong class="ml-2">Loading...</strong>
        </div>
      </template>
    </b-table>

    <Pagination
      @handleChangeTake="handleChangeTake"
      @pagination="pagination"
      :filter="filter"
      :rows="rows"
    />

    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      :hideStatusFilter="false"
      :placeholder="'Search Name'"
      @searchAll="submitFilter"
      @clearFilter="clearFilter"
      :hideSearchBar="true"
    >
      <template v-slot:filter-option>
        <b-form-group>
          <template #label>
            <div class="mb-2 main-label"><b>Order Status</b></div>

            <b-form-checkbox
              v-model="selectAll"
              aria-describedby="Status"
              aria-controls="Status"
              @change="StatusToggleAll"
            >
              Select All
            </b-form-checkbox>
          </template>

          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              class="ml-4"
              id="checkbox-group-2"
              stacked
              v-model="filter.random_reward_order_status"
              :aria-describedby="ariaDescribedby"
              @change="clearSelectAll"
            >
              <b-form-checkbox :value="1" id="waiting"
                >รอจัดส่ง</b-form-checkbox
              >
              <b-form-checkbox :value="2" id="waiting-address"
                >รอกรอกข้อมูล
              </b-form-checkbox>
              <b-form-checkbox :value="3" id="pickup-branch"
                >รับที่ร้านค้า
              </b-form-checkbox>
              <b-form-checkbox :value="4" id="success">สำเร็จ</b-form-checkbox>
              <b-form-checkbox :value="5" id="prepare"
                >กำลังจัดเตรียมสินค้า
              </b-form-checkbox>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
        <b-form-group>
          <template #label>
            <div class="mb-2 main-label"><b>Pick up Type</b></div>

            <b-form-checkbox
              v-model="pickupAll"
              aria-describedby="Status"
              aria-controls="Status"
              @change="pickupToggleAll"
            >
              Select All
            </b-form-checkbox>
          </template>

          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              class="ml-4"
              id="pickup-group"
              stacked
              v-model="filter.pick_up_type"
              :aria-describedby="ariaDescribedby"
              @change="clearPickupAll"
            >
              <b-form-checkbox :value="1" id="pick-up-branch">
                Pick up at branch
              </b-form-checkbox>
              <b-form-checkbox :value="2" id="pick-up-home">
                Pick up at home
              </b-form-checkbox>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
        <b-form-group>
          <template #label>
            <div class="mb-2 main-label"><b>Product Type</b></div>
            <b-form-checkbox
              v-model="productTypeAll"
              aria-describedby="Status"
              aria-controls="Status"
              @change="productTypeToggle"
            >
              Select All
            </b-form-checkbox>
          </template>

          <template v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group
              class="ml-4"
              id="pickup-group"
              stacked
              v-model="filter.product_type"
              :aria-describedby="ariaDescribedby"
              @change="clearProductAll"
            >
              <b-form-checkbox :value="1" id="no-reward">
                No Reward
              </b-form-checkbox>
              <b-form-checkbox :value="2" id="product">
                Product
              </b-form-checkbox>
              <b-form-checkbox :value="3" id="coupon"> Coupon </b-form-checkbox>
              <b-form-checkbox :value="4" id="point"> Point </b-form-checkbox>
            </b-form-checkbox-group>
          </template>
        </b-form-group>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
export default {
  props: ["form"],
  data() {
    return {
      isBusy: false,
      statColumn: [
        {
          name: "All Redeem",
          key: "count_Redeemed",
          isPercent: false,
          isDecimal: false,
        },

        {
          name: "In Progress",
          key: "in_Progress",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "Success",
          key: "success",
          isPercent: false,
          isDecimal: false,
        },
        {
          name: "No Reward",
          key: "no_Reward",
          isPercent: false,
          isDecimal: false,
        },
      ],
      data: {},
      rows: 0,
      filter: {
        random_reward_id: this.$route.params.id,
        search: "",
        random_reward_order_status: [1, 2, 3, 4, 5], // list int
        pick_up_type: [1, 2],
        PageNumber: 1,
        RecordPerPage: 5,
        product_type: [1, 2, 3, 4],
        page: 1,
        take: 5,
      },
      fields: [
        { key: "id", label: "", class: "w-1" },
        { key: "customer_name", label: "Name - Surname", class: "w-100px" },
        { key: "member_id", label: "Member ID", class: "w-1" },
        { key: "telephone", label: "Tel", class: "w-1" },
        { key: "email", label: "Email", class: "w-1" },
        { key: "item_name", label: "Gift", class: "w-2" },
        { key: "pick_up_type", label: "Pick up", class: "w-1" },
        { key: "random_reward_order_status", label: "Status", class: "w-2" },
        {
          key: "updated_time",
          label: "Date / Time Received",
          class: "w-100px",
        },
        { key: "note", label: "Note", class: "w-50px" },
      ],
      isLoading: true,
      listEditOrder: [],
      ListIdAll: [],
      selectAllList: 0,
      selectAll: true,
      pickupAll: true,
      productTypeAll: true,
      actionDisabled: [],
      isSelect: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    textPickup(id) {
      switch (id) {
        case 1:
          return "Pick up at branch";
        case 2:
          return "Pick up at home";

        default:
          return "None";
      }
    },
    textStatus(id, mode) {
      if (mode == 1) {
        switch (id) {
          case 1:
            return "รอจัดส่ง";
          case 2:
            return "รอกรอกข้อมูล";
          case 3:
            return "รับที่ร้านค้า";
          case 4:
            return "สำเร็จ";
          case 5:
            return "กำลังจัดเตรียมสินค้า";
          default:
            return "-";
        }
      }
      if (mode == 2) {
        switch (id) {
          case 1:
            return "text-primary";
          case 2:
            return "text-warning";
          case 3:
            return "text-primary";
          case 4:
            return "text-success";
          case 5:
            return "text-info";
          default:
            return "-";
        }
      }
    },
    async getData() {
      this.isBusy = true;
      const resp = await this.axios.post("/RandomReward/report", this.filter);
      resp.data.detail.list_order_ids = resp.data.detail.list_order_ids.map(
        (el) => el.id
      );
      this.selectAllList = 0;
      this.ListIdAll = resp.data.detail.list_order_ids.map((el) => el.id);
      this.isSelect = [];
      this.data = resp.data.detail;
      this.rows = resp.data.detail.order_detail.totalRecord;
      this.isBusy = false;
      this.isLoading = false;
      this.$emit("emitActive", resp.data.detail.order_detail.totalRecord);
    },
    async fetchData() {
      this.isBusy = true;
      const resp = await this.axios.post("/RandomReward/report", this.filter);
      resp.data.detail.list_order_ids = resp.data.detail.list_order_ids.map(
        (el) => el.id
      );
      this.ListIdAll = resp.data.detail.list_order_ids.map((el) => el.id);
      if (this.selectAllList) {
        this.isSelect = resp.data.detail.list_order_ids;
        this.listEditOrder = resp.data.detail.list_order_ids.map((el) => el.id);
      } else {
        this.isSelect = this.isSelect.filter((el) =>
          resp.data.detail.list_order_ids.find((e) => e.id == el.id)
        );
        this.listEditOrder = this.isSelect
          .filter((el) =>
            resp.data.detail.list_order_ids.find((e) => e.id == el.id)
          )
          .map((el) => el.id);
      }
      this.data = resp.data.detail;
      this.rows = resp.data.detail.order_detail.totalRecord;
      this.isBusy = false;
      this.$emit("emitActive", resp.data.detail.order_detail.totalRecord);
    },
    handleChangeTake(value) {
      this.filter.page = 1;
      this.filter.PageNumber = 1;
      this.filter.take = value;
      this.filter.RecordPerPage = value;
      this.fetchData();
    },
    pagination(val) {
      this.filter.page = val;
      this.filter.PageNumber = val;
      this.fetchData();
    },
    handleSelect(val, items) {
      if (this.isSelect.find((el) => el.id == val))
        this.isSelect.splice(
          this.isSelect.findIndex((el) => el.id == val),
          1
        );
      else this.isSelect.push(items);

      this.selectAllList =
        this.ListIdAll.length == this.isSelect.length ? 1 : 0;
    },

    async exportData() {
      const res = await this.axios.post(
        `/RandomReward/export_order`,
        {
          is_all: this.listEditOrder.length > 0 ? 0 : 1, // if 0 == not all, if 1 == all
          random_reward_id: this.$route.params.id,
          random_reward_order_id: this.listEditOrder, // list int
          pick_up_type: this.filter.pick_up_type,
          random_reward_order_status: this.filter.random_reward_order_status,
          product_type: this.filter.product_type,
        },

        {
          responseType: "blob",
        }
      );
      let data = res;

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      // var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `random-reward-${this.form.name}-report.xlsx`
      );
      document.body.appendChild(fileLink);
      fileLink.click();
      this.$bus.$emit("hideLoading");
    },
    async confirmAction(status) {
      let payload = {
        id: this.listEditOrder, // list random_reward_order_id
        random_reward_order_status: status,
      };
      this.$bus.$emit("showLoading");
      const resp = await this.axios.post(
        "RandomReward/edit_order_status",
        payload
      );
      this.$bus.$emit("hideLoading");
      if (resp.data.result) this.getData(), (this.listEditOrder = []);
      else this.errorAlert(resp.data.message);
    },
    selectAllTable(value) {
      if (value) {
        this.isSelect = JSON.parse(JSON.stringify(this.data.list_order_ids));
        this.listEditOrder = this.ListIdAll;
      } else {
        this.listEditOrder = [];
        this.isSelect = [];
      }
    },
    clearFilter() {
      this.filter = {
        random_reward_id: this.$route.params.id,
        search: "",
        random_reward_order_status: [1, 2, 3, 4, 5], // list int

        pick_up_type: [1, 2],
        product_type: [1, 2, 3, 4],
        PageNumber: 1,
        RecordPerPage: 5,
        page: 1,
        take: 5,
      };
      this.productTypeAll = true;
      this.selectAll = true;
      this.pickupAll = true;
    },

    showFilter() {
      this.$refs.sidebarFilter.show();
    },
    StatusToggleAll(checked) {
      this.filter.random_reward_order_status = checked ? [1, 2, 3, 4, 5] : [];
    },
    pickupToggleAll(checked) {
      this.filter.pick_up_type = checked ? [1, 2] : [];
    },
    productTypeToggle(checked) {
      this.filter.product_type = checked ? [1, 2, 3, 4] : [];
    },
    clearSelectAll() {
      this.selectAll =
        this.filter.random_reward_order_status.length == 5 ? true : false;
    },
    clearPickupAll() {
      this.pickupAll = this.filter.pick_up_type.length == 2 ? true : false;
    },
    clearProductAll() {
      this.productTypeAll = this.filter.product_type.length == 4 ? true : false;
    },
    submitFilter() {
      this.filter.PageNumber = 1;
      this.filter.page = 1;
      this.fetchData();
    },
  },
};
</script>

<style scoped>
::v-deep .dropdown-item.disabled {
  background: whitesmoke;
}
::v-deep .dropdown-item:hover:not(.disabled) {
  background-color: var(--primary-color);
  color: var(--font-primary-color) !important;
}
::v-deep .dropdown-menu {
  overflow: hidden;
  padding: 0;
}
</style>
